import { useState, useEffect } from "react";
import Puzzul from "../image/Puzzul.svg";
import gqlQuery from "../GraphQL";
import { Row, Col, Button, Tab, Modal, ListGroup } from "react-bootstrap";
import QRCode from "qrcode.react";
import { useQuery, useMutation } from "@apollo/client";

function ListOfTemplates({ selectedTemplate, setSelectTemplate }) {
  const { loading, error, data } = useQuery(gqlQuery.getTemplates, {
    variables: {},
  });

  if (loading) {
    return <>Loading</>;
  }

  if (error) {
    return <>Error</>;
  }

  return (
    <ListGroup defaultActiveKey="#link1">
      {data.getTemplates.map((e) => {
        if (e._id === selectedTemplate) {
          return (
            <ListGroup.Item
              key={"List" + e._id}
              action
              active
              onClick={() => {
                setSelectTemplate("");
              }}
            >
              {e.name}
            </ListGroup.Item>
          );
        }
        return (
          <ListGroup.Item
            key={"List" + e._id}
            action
            onClick={() => {
              setSelectTemplate(e._id);
            }}
          >
            {e.name}
          </ListGroup.Item>
        );
      })}
      <ListGroup.Item
        action
        style={{ backgroundColor: "rgb(235, 88, 88)", color: "white" }}
        onClick={() => {}}
      >
        New Template
      </ListGroup.Item>
    </ListGroup>
  );
}

function QRCodeImage({ link }) {
  return <QRCode value={link} />;
}

function ShowLink({ show, handleClose, infoLink }) {
  const { loading, error, data } = useQuery(gqlQuery.getLinkForAsset, {
    variables: { ...infoLink },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    <></>;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Link to Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          {!loading ? (
            <QRCodeImage link={"puzzul.org/#/f/" + data.getLinkForAsset} />
          ) : (
            <></>
          )}
        </div>
        {"puzzul.org/#/f/" + data.getLinkForAsset}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function TemplatesAttachedToAsset({ address }) {
  const { loading, error, data } = useQuery(gqlQuery.getAsset, {
    variables: { id: address },
  });

  const [addTemplateToToken] = useMutation(gqlQuery.addTemplateToToken, {
    refetchQueries: [{ query: gqlQuery.getAsset, variables: { id: address } }],
  });
  const [removeTemplateFromToken] = useMutation(
    gqlQuery.removeTemplateFromToken,
    {
      refetchQueries: [
        { query: gqlQuery.getAsset, variables: { id: address } },
      ],
    }
  );

  const [show, setShow] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [infoLink, setInfoLink] = useState({});
  let [selectedTemplate, setSelectTemplate] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseLink = () => setShowLink(false);
  const handleShowLink = () => setShowLink(true);

  let info = {};
  let docs = [];
  let secureDocs = [];

  if (error) {
    console.log(error);
  }

  if (data) {
    console.log(data);
    if (data.getAsset) {
      if (data.getAsset.asset) {
        info = data.getAsset.asset;
        docs = data.getAsset.docs;
        secureDocs = data.getAsset.secureDocs;
      }
    }
  }

  if (loading) {
    return <Tab.Pane eventKey={address}>Loading...</Tab.Pane>;
  }

  return (
    <>
      {infoLink.asset ? (
        <ShowLink
          show={showLink}
          handleClose={handleCloseLink}
          infoLink={infoLink}
        />
      ) : (
        <></>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Template to this Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTemplate === ""
            ? "Please Select the Template from the list below."
            : "Add Selected Template to Asset"}
        </Modal.Body>
        <ListOfTemplates
          selectedTemplate={selectedTemplate}
          setSelectTemplate={setSelectTemplate}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              try {
                await addTemplateToToken({
                  variables: {
                    asset: address,
                    template: selectedTemplate,
                  },
                });
                handleClose();
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ marginTop: "20px" }}></div>

      <Tab.Pane eventKey={address}>
        <h3>Open Templates</h3>
        Address: {address}
        <div style={{ marginTop: "20px" }}></div>
        <h4>Attached Templates</h4>
        {docs.map((e) => {
          return (
            <Row>
              <Col>
                <h5>{e.name}</h5>
              </Col>
              <Col
                onClick={async (_e) => {
                  await removeTemplateFromToken({
                    variables: {
                      asset: address,
                      template: e._id,
                    },
                  });
                }}
              >
                <h5>Remove</h5>
              </Col>
              <Col
                onClick={() => {
                  setInfoLink({
                    asset: address,
                    template: e._id,
                  });
                  handleShowLink();
                }}
              >
                <h5>Link</h5>
              </Col>
            </Row>
          );
        })}
        <div style={{ marginTop: "20px" }}></div>
        <Button
          variant="primary"
          onClick={() => {
            handleShow();
          }}
        >
          Add Template
        </Button>
      </Tab.Pane>

      <Tab.Pane eventKey={address}>
        <h3>Secure Templates</h3>
        Address: {address}
        <div style={{ marginTop: "20px" }}></div>
        <h4>Attached Templates</h4>
        {secureDocs.map((e) => {
          return (
            <Row>
              <Col>
                <h5>{e.name}</h5>
              </Col>
              <Col
                onClick={async (_e) => {
                  await removeTemplateFromToken({
                    variables: {
                      asset: address,
                      template: e._id,
                    },
                  });
                }}
              >
                <h5>Remove</h5>
              </Col>
              <Col
                onClick={() => {
                  setInfoLink({
                    asset: address,
                    template: e._id,
                  });
                  handleShowLink();
                }}
              >
                <h5>Link</h5>
              </Col>
            </Row>
          );
        })}
        <div style={{ marginTop: "20px" }}></div>
        <Button
          variant="primary"
          onClick={() => {
            handleShow();
          }}
        >
          Add Template
        </Button>
      </Tab.Pane>
    </>
  );
}

export default TemplatesAttachedToAsset;
