import { useState } from "react";
import { useHistory } from "react-router-dom";
import Puzzul from "../image/Puzzul.svg";
import gqlQuery from "../GraphQL";
import {
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";

import { useQuery, useMutation } from "@apollo/client";

const typeToLong = (_type) => {
  if (_type === "textBox") {
    return "Long Answer";
  } else if (_type === "multiChoice") {
    return "Multiple Choice";
  } else if (_type === "noQuestion") {
    return "Just Description";
  } else if (_type === "text") {
    return "Short Answer";
  } else {
    return "";
  }
};

const longToType = (phrase) => {
  if (phrase === "Long Answer") {
    return "textBox";
  } else if (phrase === "Multiple Choice") {
    return "multiChoice";
  } else if (phrase === "Just Description") {
    return "noQuestion";
  } else if (phrase === "Short Answer") {
    return "text";
  } else {
    return "";
  }
};

const moveItem = (data, from, to) => {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0];
  // insert stored item into position `to`
  data.splice(to, 0, f);
};

function ViewTemplate({ navigation, match }) {
  const { loading, error, data } = useQuery(gqlQuery.getTemplate, {
    variables: { id: match.params.Lookup },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }

  let template = data.getTemplate;

  return (
    <ViewTemplateSecond
      navigation={navigation}
      match={match}
      _template={template}
    />
  );
}

const formBoxCss = {
  marginBottom: "50px",
  borderStyle: "solid",
  padding: "20px",
};

function ViewTemplateSecond({ navigation, match, _template }) {

  let history = useHistory();
  const [updateTemplate] = useMutation(gqlQuery.updateTemplate);

  const [template, setTemplate] = useState(_template);

  const [info, setInfo] = useState({});
  const [editOrView, setEditOrView] = useState(false);

  if (!editOrView) {
    return (
      <>
        <div style={{ background: "#85c4ca", color: "white" }}>
          <Row style={{ height: "50px" }}></Row>
          <Row>
            <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
              {" "}
              <div
                style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
              >
                Puzzul
              </div>
              <div style={{ color: "white", fontSize: "30px" }}>
                Community Governed, Socially Oriented, Data Management
              </div>
              <div style={{ color: "white", marginTop: "30px" }}>

                  
                        <Form.Group controlId="label">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Text that will appear on top"
                            value={template.name}
                            onChange={(_e) => {
                              let text = _e.target.value;
                              let _info = { ...template };
                              _info.name = text;
                              setTemplate(_info);
                            }}
                          />
                        </Form.Group>





                <Form.Group key={"Description"} controlId={"Description"}>
                  <Form.Label>Template Description</Form.Label>

                  <FormControl
                    onChange={(_e) => {
                      let text = _e.target.value;
                      let _info = { ...template };
                      _info.description = text;
                      setTemplate(_info);
                    }}
                    value={template.description}
                    as="textarea"
                    aria-label="With textarea"
                    placeholder={"Enter the Description for this Template"}
                  />
                  <Form.Text>
                    This will be displayed at the top of the form and it a good
                    place to add information to the form
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <img height={360} src={Puzzul} />
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center" }}
              onClick={() => {
                setEditOrView(false);
              }}
            >
              <h1>Edit</h1>
            </Col>
            <Col
              style={{ textAlign: "center" }}
              onClick={() => {
                setEditOrView(true);
              }}
            >
              <h1>View</h1>
            </Col>
          </Row>

          <Form style={{ padding: "30px" }}>
            {template.questions.map((e, index) => {
              if (e.type === "textBox") {
                return (
                  <>
                    <Form style={formBoxCss}>
                      <Form.Row>
                        <Form.Group as={Col} controlId="label">
                          <Form.Label>Label</Form.Label>
                          <Form.Control
                            placeholder="Text that will appear on top"
                            value={e.label}
                            onChange={(_e) => {
                              let text = _e.target.value;
                              let _info = { ...template };
                              _info.questions[index].label = text;
                              setTemplate(_info);
                            }}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="placeholder">
                          <Form.Label>Placeholder</Form.Label>
                          <Form.Control
                            placeholder="Text in the box will appear here"
                            value={e.placeholder}
                            onChange={(_e) => {
                              let text = _e.target.value;
                              let _info = { ...template };
                              _info.questions[index].placeholder = text;
                              setTemplate(_info);
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Label>Description</Form.Label>
                      <FormControl
                        onChange={(_e) => {
                          let text = _e.target.value;
                          let _info = { ...template };
                          _info.questions[index].text = text;
                          setTemplate(_info);
                        }}
                        value={e.text}
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="Text Below The Question"
                      />
                      <Form.Group controlId="formGridState">
                        <Form.Label>Question Type</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={typeToLong(e.type)}
                          onClick={(_e) => {
                            let phrase = _e.target.value;

                            let newMode = longToType(phrase);

                            let _info = { ...template };
                            _info.questions[index].type = newMode;
                            setTemplate(_info);
                          }}
                        >
                          <option>Short Answer</option>
                          <option>Long Answer</option>
                          <option>Multiple Choice</option>
                          <option>Just Description</option>
                        </Form.Control>
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          _info.questions.splice(index, 1);
                          setTemplate(_info);
                        }}
                      >
                        Remove Question
                      </Button>
                      {""}{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };

                          if (index !== 0) {
                            moveItem(_info.questions, index, index - 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Up
                      </Button>{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          if (index !== _info.questions - 1) {
                            moveItem(_info.questions, index, index + 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Down
                      </Button>{" "}
                      {"Slide # " + index}
                    </Form>
                  </>
                );
              } else if (e.type === "noQuestion") {
                return (
                  <>
                    <Form style={formBoxCss}>
                      <Form.Label>Description</Form.Label>
                      <FormControl
                        onChange={(_e) => {
                          let text = _e.target.value;
                          let _info = { ...template };
                          _info.questions[index].text = text;
                          setTemplate(_info);
                        }}
                        value={e.text}
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="Text Below The Question"
                      />
                      <Form.Group controlId="formGridState">
                        <Form.Label>Question Type</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={typeToLong(e.type)}
                          onClick={(_e) => {
                            let phrase = _e.target.value;

                            let newMode = longToType(phrase);

                            let _info = { ...template };
                            _info.questions[index].type = newMode;
                            setTemplate(_info);
                          }}
                        >
                          <option>Short Answer</option>
                          <option>Long Answer</option>
                          <option>Multiple Choice</option>
                          <option>Just Description</option>
                        </Form.Control>
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          _info.questions.splice(index, 1);
                          setTemplate(_info);
                        }}
                      >
                        Remove Question
                      </Button>
                      {""}{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };

                          if (index !== 0) {
                            moveItem(_info.questions, index, index - 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Up
                      </Button>{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          if (index !== _info.questions - 1) {
                            moveItem(_info.questions, index, index + 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Down
                      </Button>{" "}
                      {"Slide # " + index}
                    </Form>
                  </>
                );
              } else if (e.type === "multiChoice") {
                return (
                  <>
                    <Form style={formBoxCss}>
                      <Form.Label>Description</Form.Label>
                      <FormControl
                        onChange={(_e) => {
                          let text = _e.target.value;
                          let _info = { ...template };
                          _info.questions[index].text = text;
                          setTemplate(_info);
                        }}
                        value={e.text}
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="Text Below The Question"
                      />
                      <div style={{ marginTop: "20px" }}></div>
                      {e.options ? (
                        e.options.map((e, _index) => {
                          return (
                            <Row>
                              <Col>{e}</Col>
                              <Col
                                onClick={() => {
                                  let _info = { ...template };
                                  _info.questions[index].options.splice(
                                    _index,
                                    1
                                  );
                                  setTemplate(_info);
                                }}
                              >
                                Remove
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <div style={{ marginTop: "20px" }}></div>
                      <Form.Control
                        placeholder="Add Choice"
                        onKeyUp={(event) => {
                 
                          if (event.keyCode === 13) {
                            // Cancel the default action, if needed
                            event.preventDefault();
                            // Trigger the button element with a click
                            let text = event.target.value;

                            let _info = { ...template };
                            if (!_info.questions[index].options) {
                              _info.questions[index].options = [];
                            }
                            _info.questions[index].options.push(text);
                            setTemplate(_info);
                          }
                        }}
                      />
                      <div style={{ marginTop: "20px" }}></div>
                      <Form.Group controlId="formGridState">
                        <Form.Label>Question Type</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={typeToLong(e.type)}
                          onClick={(_e) => {
                            let phrase = _e.target.value;

                            let newMode = longToType(phrase);

                            let _info = { ...template };
                            _info.questions[index].type = newMode;
                            setTemplate(_info);
                          }}
                        >
                          <option>Short Answer</option>
                          <option>Long Answer</option>
                          <option>Multiple Choice</option>
                          <option>Just Description</option>
                        </Form.Control>
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          _info.questions.splice(index, 1);
                          setTemplate(_info);
                        }}
                      >
                        Remove Question
                      </Button>
                      {""}{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };

                          if (index !== 0) {
                            moveItem(_info.questions, index, index - 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Up
                      </Button>{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          if (index !== _info.questions - 1) {
                            moveItem(_info.questions, index, index + 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Down
                      </Button>{" "}
                      {"Slide # " + index}
                    </Form>
                  </>
                );
              } else if (e.type === "text") {
                return (
                  <>
                    <Form style={formBoxCss}>
                      <Form.Row>
                        <Form.Group as={Col} controlId="label">
                          <Form.Label>Label</Form.Label>
                          <Form.Control
                            placeholder="Text that will appear on top"
                            value={e.label}
                            onChange={(_e) => {
                              let text = _e.target.value;
                              let _info = { ...template };
                              _info.questions[index].label = text;
                              setTemplate(_info);
                            }}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="placeholder">
                          <Form.Label>Placeholder</Form.Label>
                          <Form.Control
                            placeholder="Text in the box will appear here"
                            value={e.placeholder}
                            onChange={(_e) => {
                              let text = _e.target.value;
                              let _info = { ...template };
                              _info.questions[index].placeholder = text;
                              setTemplate(_info);
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Label>Description</Form.Label>
                      <FormControl
                        onChange={(_e) => {
                          let text = _e.target.value;
                          let _info = { ...template };
                          _info.questions[index].text = text;
                          setTemplate(_info);
                        }}
                        value={e.text}
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="Text Below The Question"
                      />
                      <Form.Group controlId="formGridState">
                        <Form.Label>Question Type</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={typeToLong(e.type)}
                          onClick={(_e) => {
                            let phrase = _e.target.value;

                            let newMode = longToType(phrase);

                            let _info = { ...template };
                            _info.questions[index].type = newMode;
                            setTemplate(_info);
                          }}
                        >
                          <option>Short Answer</option>
                          <option>Long Answer</option>
                          <option>Multiple Choice</option>
                          <option>Just Description</option>
                        </Form.Control>
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          _info.questions.splice(index, 1);
                          setTemplate(_info);
                        }}
                      >
                        Remove Question
                      </Button>
                      {""}{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };

                          if (index !== 0) {
                            moveItem(_info.questions, index, index - 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Up
                      </Button>{" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          let _info = { ...template };
                          if (index !== _info.questions - 1) {
                            moveItem(_info.questions, index, index + 1);
                          }
                          setTemplate(_info);
                        }}
                      >
                        Move Down
                      </Button>{" "}
                      {"Slide # " + index}
                    </Form>
                  </>
                );
              }
            })}

            <>
              <Form style={formBoxCss}>
                <Form.Group as={Col} controlId="label">
                  <Form.Label>Add Question </Form.Label>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={() => {
                    let _info = { ...template };
                    _info.questions.push({
                      label: "",
                      varName: "",
                      text: "",
                      placeholder: "",
                      type: "textBox",
                    });
                    setTemplate(_info);
                  }}
                >
                  Add Question
                </Button>
              </Form>
            </>

            <Button
              variant="primary"
              onClick={async () => {
              
                try {
                  let data = await updateTemplate({
                    variables: {
                      json:JSON.stringify(template)
                    }});

                    if (data.data.updateTemplate){
                      history.push('/templates');
                    }


                } catch (error) {
                  
                }
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div style={{ background: "#85c4ca", color: "white" }}>
          <Row style={{ height: "50px" }}></Row>
          <Row>
            <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
              {" "}
              <div
                style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
              >
                Puzzul
              </div>
              <div style={{ color: "white", fontSize: "30px" }}>
                Community Governed, Socially Oriented, Data Management
              </div>
              <div style={{ color: "white", marginTop: "30px" }}>
                {template.description}
              </div>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <img height={360} src={Puzzul} alt=""/>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center" }}
              onClick={() => {
                setEditOrView(false);
              }}
            >
              <h1>Edit</h1>
            </Col>
            <Col
              style={{ textAlign: "center" }}
              onClick={() => {
                setEditOrView(true);
              }}
            >
              <h1>View</h1>
            </Col>
          </Row>

          <Form style={{ padding: "30px" }}>
            {template.questions.map((e, index)=>{
              if (e.type === "textBox") {
                return (
                  <Form.Group
                    key={"Questions" + index}
                    controlId={"var" + index}
                  >
                    <Form.Label>{e.label}</Form.Label>

                    <FormControl
                      onChange={(_e) => {
                        let text = _e.target.value;
                        let _info = { ...info };
                        _info[index] = text;
                        setInfo(_info);
                      }}
                      value={info[index]}
                      as="textarea"
                      aria-label="With textarea"
                      placeholder={e.placeholder}
                    />
                    <Form.Text>{e.text}</Form.Text>
                  </Form.Group>
                );
              } else if (e.type === "noQuestion") {
                return (
                  <Form.Group
                    key={"Questions" + index}
                    controlId={"var" + index}
                  >
                    <Form.Label>{e.text}</Form.Label>
                  </Form.Group>
                );
              } else if (e.type === "multiChoice") {
                return (
                  <Form.Group
                    key={"Questions" + index}
                    controlId={"var" + index}
                  >
                    <Form.Label>{e.label}</Form.Label>

                    <Form.Control
                      as="select"
                      onClick={(_e) => {
                        let phrase = _e.target.value;

                        for (
                          let index = 0, length = e.options.length;
                          index < length;
                          index++
                        ) {
                          if (phrase === e.options[index]) {
                            let _info = { ...info };
                            _info[index] = index;
                            setInfo(_info);
                          }
                        }
                      }}
                    >
                      {e.options.map((e, index2) => {
                        return (
                          <option key={"Option" + index + "#" + index2}>
                            {e}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Text>{e.text}</Form.Text>
                  </Form.Group>
                );
              } else if (e.type === "text") {
                return (
                  <Form.Group
                    key={"Questions" + index}
                    controlId={"var" + index}
                  >
                    <Form.Label>{e.label}</Form.Label>
                    <Form.Control
                      onChange={(_e) => {
                        let text = _e.target.value;
                        let _info = { ...info };
                        _info[index] = text;
                        setInfo(_info);
                      }}
                      value={info[index]}
                      type="text"
                      placeholder={e.placeholder}
                    />
                    <Form.Text>{e.text}</Form.Text>
                  </Form.Group>
                );
              } else {
                return<></>
              }
            })}
            <Button
              variant="primary"
              onClick={() => {
                console.log({
                  info,
                });
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </>
    );
  }
}

export default ViewTemplate;
