import { useState } from "react";
import { Radar } from "react-chartjs-2";
import { getAssets } from "../DataGather";

import { Dropdown } from "react-bootstrap";

const maxESG = (esgScore, value) => {
  esgScore.ei = value.ei < esgScore.ei ? esgScore.ei : value.ei;
  esgScore.ge = value.ge < esgScore.ge ? esgScore.ge : value.ge;
  esgScore.re = value.re < esgScore.re ? esgScore.re : value.re;
  esgScore.se = value.se < esgScore.se ? esgScore.se : value.se;
  esgScore.ne = value.ne < esgScore.ne ? esgScore.ne : value.ne;
  esgScore.rd = value.rd < esgScore.rd ? esgScore.rd : value.rd;
};

const minESG = (esgScore, value) => {
  esgScore.ei = value.ei > esgScore.ei ? esgScore.ei : value.ei;
  esgScore.ge = value.ge > esgScore.ge ? esgScore.ge : value.ge;
  esgScore.re = value.re > esgScore.re ? esgScore.re : value.re;
  esgScore.se = value.se > esgScore.se ? esgScore.se : value.se;
  esgScore.ne = value.ne > esgScore.ne ? esgScore.ne : value.ne;
  esgScore.rd = value.rd > esgScore.rd ? esgScore.rd : value.rd;
};

const weightedESG = (esgScore, value,weight) => {
  esgScore.ei += value.ei*weight;
  esgScore.ge += value.ge*weight;
  esgScore.re += value.re*weight;
  esgScore.se += value.se*weight;
  esgScore.ne += value.ne*weight;
  esgScore.rd += value.rd*weight;
};

const radarDataFromAssets = (assets, mode) => {


  let esgScore = {
    ei: 0.0,
    ge: 0.0,
    re: 0.0,
    se: 0.0,
    ne: 0.0,
    rd: 0.0,
  };


  if (mode === 0) {
    for (let index = 0, length = assets.length; index < length; index++) {
        maxESG(esgScore, assets[index].esg);
    }
    } else if (mode === 1) {

        esgScore = {
            ei: 1.0,
            ge: 1.0,
            re: 1.0,
            se: 1.0,
            ne: 1.0,
            rd: 1.0,
          };

        for (let index = 0, length = assets.length; index < length; index++) {
            minESG(esgScore, assets[index].esg);
        }
        
    } else if (mode === 2) {

    let totalValue = 0;

    for (let index = 0, length = assets.length; index < length; index++) {
        totalValue += assets[index].lastEval
    }


    for (let index = 0, length = assets.length; index < length; index++) {
        weightedESG(esgScore, assets[index].esg, assets[index].lastEval/totalValue )
    }


} else if (mode === 3) {

    let totalValue = 0;

    for (let index = 0, length = assets.length; index < length; index++) {
        totalValue += assets[index].purchase
    }
 

    for (let index = 0, length = assets.length; index < length; index++) {
        weightedESG(esgScore, assets[index].esg, assets[index].purchase/totalValue )
    }


  }



  return {
    labels: [
      "Enviromental Impact",
      "Gender Equity",
      "Racial Equity",
      "Sexual Expression Equity",
      "Non English Programming",
      "Rural Urban Divide",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [
          esgScore.ei,
          esgScore.ge,
          esgScore.re,
          esgScore.se,
          esgScore.ne,
          esgScore.rd,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
};


const options = {
  scale: {
    ticks: { beginAtZero: true, max:1},
  },
  legend: {
    display: false,
  },
};

const ESGgoals = ({assets}) => 
{
 
  

  const [mode, setMode] = useState(3);
  return <>
    <Radar data={radarDataFromAssets(assets,mode)} options={options} />
    <div style={{marginTop:"20px"}}></div>
    <Dropdown id="Analysis">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    {
      (mode===0)?"Max Impact":(mode===1)?"Min Impact":(mode===2)?"Weight by Value":(mode===3)?"Weight by Initial Investment":""
    }
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1" onClick={(e)=>{
        e.preventDefault();
        setMode(0);
    }}>Max Impact</Dropdown.Item>
    <Dropdown.Item href="#/action-2" onClick={(e)=>{
        e.preventDefault();
        setMode(1);
    }}>Min Impact</Dropdown.Item>
    <Dropdown.Item href="#/action-3" onClick={(e)=>{
        e.preventDefault();
        setMode(2);
    }}>Weight by Value</Dropdown.Item>
    <Dropdown.Item href="#/action-3" onClick={(e)=>{
        e.preventDefault();
        setMode(3);
    }}>Weight by Initial Investment</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
<div style={{marginTop:"40px"}}></div>
  </>
};

export default ESGgoals;
