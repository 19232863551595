import Puzzul from "../image/Puzzul.svg";
import gqlQuery from "../GraphQL";
import {
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import TemplatesAttachedToAsset from './TemplatesAttachedToAsset';
import { useQuery } from "@apollo/client";

function TemplateManager({ navigation, match }) {
  const portData = useQuery(gqlQuery.getAPortfolio, {
    variables: { id: "All" },
  });

  let assets = [];

  if (portData.data) {
    if (portData.data.getAPortfolio) {
      assets = [...portData.data.getAPortfolio];
    }
  }

  return (
    <>
      <div
        style={{
          background: "#85c4ca",
          color: "white",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Row style={{ height: "50px" }}></Row>
        <Row>
          <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
            {" "}
            <div
              style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
            >
              Puzzul
            </div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Community Governed, Socially Oriented, Data Management
            </div>
            <div style={{ color: "white", marginTop: "30px" }}>
              <h1>Templates</h1>
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <img height={360} src={Puzzul} alt="" />
          </Col>
        </Row>

        <div style={{ margin: "30px" }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {assets.map((e) => {
                    return (
                      <Nav.Item key={e.address}>
                        <Nav.Link eventKey={e.address}>{e.name}</Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {assets.map((e) => {
                    return (
                      <TemplatesAttachedToAsset
                        key={"TemplateA" + e.address}
                        address={e.address}
                      />
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default TemplateManager;
