
import React, { useState } from 'react';
import { createEncryptedDocumentForMultiplePeople} from '../Helper/EncryptAlgo';
import Puzzul from "../image/Puzzul.svg";
import primia from '../image/Primia.png';
import iModX from '../image/iModX-C.svg';
import {Row,Col, Container, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import gqlQuery from "../GraphQL";
import { useMutation } from "@apollo/client";



let keys = {
  "pub":
  {
    "alg": "RSA-OAEP-256",
    "e": "AQAB",
    "ext": true,
    "key_ops": ["encrypt"],
    "kty": "RSA",
    "n": "vyQcnG3GW7tLM6rDY3KQyS1S2LJezh2SELjLQ2OCsXYF6xW1BtPnJDdB2BXd66Gzag4eDfIVaPKoZjQdUVlb9mmXbMPFy6jLGN4HSWSlBe8M7xEGnAlitIG14ybli1RA7CjTsdUuKvQW43BVYePUh5En__BqYLHzow-_Yo5XYzeVKfHGyikOWkKvw6t6y3gOp3Tg4Z8xvEYqeaXa5wWGX_NertM8bfDwn2RR_Uml-gokx5xDicjExeZdkcmyJ5ltp_dh_u8e-36WVM6ozbISy9cLaHlMfmazm5AL4y9HBmPJuVKjeUgOWUHGrZSo89WaLQFi5asA4AWpWrxZQzlWhw"
  },
}


let encKey = keys.pub;

function App() {

  const [submitForm] = useMutation(gqlQuery.answerASecureForm);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [assets, setAssets] = useState("");
  const [bestDescribe, setBestDescribe] = useState("");
  const [otherDesc, setOtherDesc] = useState("");
  const [geo, setGeo] = useState("");
  const [country, setCountry] = useState("");
  const [secureKey, setSecureKey] = useState("");

  const [encryptedData, setEncryptedData] = useState({});


  return (
    <div>
      <Container fluid={true} >

      <Row>
          <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
            {" "}
            <div
              style={{ color: "white", fontSize: "32px", fontWeight: "bold" }}
            >
              <img height={32} src={Puzzul} alt="" />Puzzul
            </div>
            <div style={{ color: "white", fontSize: "12px" }}>
              Community Governed, Socially Oriented, Data Management
            </div>
  
          </Col>
          <Col style={{ textAlign: "center" }}>
            
          </Col>
        </Row>



        <Form>
        <img src={primia} height={360} alt="logo" />
        <img src={iModX}  height={360} alt="logo" />
        <div style={{ color: "white", marginTop: "30px", fontSize: "48px", fontWeight: "bold"  }}>
              Enterprise Wide Stress Testing
            </div>
        
          <Form.Label><h3>Generate Secure Token For EWST Survey</h3></Form.Label>

          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
          </Form.Group>

          <Form.Group controlId="lastName">
            <Form.Label>Second Name</Form.Label>
            <Form.Control value={secondName} onChange={(e) => { setSecondName(e.target.value) }} />
          </Form.Group>

          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control value={title} onChange={(e) => { setTitle(e.target.value) }} />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control value={email} onChange={(e) => { setEmail(e.target.value) }} />
          </Form.Group>

          <Form.Group controlId="organization">
            <Form.Label>Organization</Form.Label>
            <Form.Control value={org} onChange={(e) => { setOrg(e.target.value) }} />
          </Form.Group>

          <Form.Group controlId="sizeOfOrg">
            <Form.Label>What is the approximate size (in USD or equivalent) of the Total Assets in your institution's balance sheet?</Form.Label>
            <Form.Control as="select" defaultValue="Choose..." onChange={(e) => { setAssets(e.target.value) }}>
              <option>Choose...</option>
              <option>Less than $1Bn</option>
              <option>$1Bn to $10Bn</option>
              <option>$10Bn to $50Bn</option>
              <option>$50Bn to $250Bn</option>
              <option>$250Bn to $1Tn</option>
              <option>More than $1Tn</option>

            </Form.Control>
          </Form.Group>

          <Form.Group controlId="typeOfOrg">
            <Form.Label>Which of the following best describes your organization?</Form.Label>
            <Form.Control as="select" defaultValue="Choose..." onChange={(e) => { setBestDescribe(e.target.value) }}>
              <option>Choose...</option>
              <option>Universal Bank</option>
              <option>Commercial Bank</option>
              <option>Insurance Company</option>
              <option>Credit Union or Building Society</option>
              <option>Trust Company</option>
              <option>Other</option>

            </Form.Control>
          </Form.Group>

          {bestDescribe=="Other"?<Form.Group controlId="defineOther">
            <Form.Label>Please Define Other</Form.Label>
            <Form.Control value={otherDesc} onChange={(e) => { setOtherDesc(e.target.value) }} />
          </Form.Group>:<></>}


          <Form.Group controlId="geographicArea">
            <Form.Label>What is your business geographical area and which country is the headquarters of your institution located?</Form.Label>
            <Form.Control as="select" defaultValue="Choose..." onChange={(e) => { setGeo(e.target.value) }}>
              <option>Choose...</option>
              <option>Domestic</option>
              <option>International</option>

            </Form.Control>
          </Form.Group>


          <Form.Group controlId="headQuarters">
            <Form.Label>Headquarters Country Location</Form.Label>
            <Form.Control value={country} onChange={(e) => { setCountry(e.target.value) }} />
          </Form.Group>


          <Form.Group controlId="generateKey">
            <Form.Label>Securely Send Information and Generate Key</Form.Label>

            <div>
              <Button onClick={async () => {

                let dataTo = {
                  firstName,
                  secondName,
                  title,
                  email,
                  org,
                  assets,
                  bestDescribe,
                  geo,
                  country
                }

                let data = await createEncryptedDocumentForMultiplePeople(dataTo, [encKey]);

                try {

                    let _data = await submitForm({
                        variables: {
                          uuid: data.doc.hash,
                          json: JSON.stringify(data),
                        },
                      });

                } catch (error) {
                  console.log(error);
                }

                

                setEncryptedData(data);
                setSecureKey(data.doc.hash)


              }}>Send</Button>
            </div>
          </Form.Group>

          <Form.Group controlId="formGridAddress1">
            <Form.Label>Your Secure Token Number Will Appear Below. Please copy and paste it in the first section of the Surveymonkey's EWST Survey. Please keep your Secure Token Number in a secure place for future identification related to the final report.</Form.Label>
            <div>
              <Form.Label>{secureKey}</Form.Label>
            </div>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}

export default App;
