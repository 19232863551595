import { useState } from "react";
import logo from "../image/Puzzul.svg";

import CurrentHoldings from "./CurrentHoldings";
import ESGgoals from "./ESGgoals";
import TokenList from './TokenList';
import gqlQuery from "../GraphQL";

import { Nav, Navbar, NavDropdown, Row, Col} from "react-bootstrap";


import { useQuery } from "@apollo/client";


function Portfolio({navigation}) {

  const [selectedPort,] = useState("All");


  const {data } = useQuery(gqlQuery.getPortfolios, {
    variables: {},
  });

  const portData = useQuery(gqlQuery.getAPortfolio, {
    variables: {id:selectedPort},
  });


  let portfolio = [];

  if(data){
    if (data.getPortfolios){
      portfolio = data.getPortfolios;
    }
  }

  let assets = [];

  if (portData.data){
    if (portData.data.getAPortfolio){
      assets =portData.data.getAPortfolio;
    }
  }



  return (
    <div
      className="App"
      style={{
        backgroundColor: "rgb(133, 196, 202)",
        color: "white",
      }}
    >
      <Navbar expand="lg">
        <Navbar.Brand
          style={{ color: "white", fontSize: "30px", fontWeight: "bold" }}
        >
          {" "}
          <img src={logo} height={32} style={{ marginRight: "20px" }} alt="" />
          Puzzul Dashboard
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav className="">
            <NavDropdown
              title="Portfolio"
              id="basic-nav-dropdown"
              style={{
                color: "white",
                fontSize: "30px",
                fontWeight: "bold",
                a: { color: "white" },
              }}
            >
              {
                portfolio.map((e)=>{
                  return <NavDropdown.Item >{e.name}</NavDropdown.Item>
                })
              }
              <NavDropdown.Divider />
              <NavDropdown.Item >All</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Row>
        <Col sm={6}>
          <CurrentHoldings assets={assets} />
        </Col>
        <Col sm={6}>
          <ESGgoals assets={assets}/>
        </Col>
      </Row>
      <TokenList navigation={navigation} portfolio={assets}/>
    </div>
  );
}

export default Portfolio;
