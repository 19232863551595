import { useState } from "react";
import Puzzul from "../image/Puzzul.svg";
import PhoneCard from "../image/phoneCard.svg";
import Crypto from "../image/crypto.svg";
import Coupon from "../image/coupon.svg";

import gqlQuery from "../GraphQL";
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";

import { useQuery, useMutation } from "@apollo/client";

const formBoxCss = {
  marginBottom: "50px",
  borderStyle: "solid",
  padding: "20px",
};

function SubmitForm({ navigation, match }) {
  const { loading, error, data } = useQuery(gqlQuery.getAForm, {
    variables: { id: match.params.Lookup },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }

  let template = data.getAForm;

  return (
    <ViewFormSecond
      navigation={navigation}
      uuid={match.params.Lookup}
      template={template}
    />
  );
}

function ViewFormSecond({ navigation, uuid, template }) {
  const [info, setInfo] = useState({});
  const [submitForm] = useMutation(gqlQuery.answerAForm);


  const [reward, setReward] = useState(false);


  if (reward){
    return <>
    <div style={{ background: "#85c4ca", color: "white" }}>
      <Row style={{ height: "50px" }}></Row>
      <Row>
        <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
          {" "}
          <div
            style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
          >
            Puzzul
          </div>
          <div style={{ color: "white", fontSize: "30px" }}>
            Community Governed, Socially Oriented, Data Management
          </div>
          <div style={{ color: "white", marginTop: "30px" }}>
            Thank you for submitting your answers to Puzzul. Feel free to choose from any of these rewards
          </div>
        </Col>
        <Col style={{ textAlign: "center" }}>
          <img height={360} src={Puzzul} alt="" />
        </Col>
      </Row>


      <Row className="align-items-center" >
        <Col style={{padding:"60px"}}>
        <img src={Crypto} alt="" />
        </Col>
        <Col style={{padding:"60px"}}>
        <img src={Coupon} alt="" />
        </Col>
        <Col style={{padding:"60px"}}>
        <img src={PhoneCard} alt="" />
        </Col>
      </Row>
      <Row >
      <Col style={{textAlign:"center"}}>
        <h4>Crypto</h4>
        </Col>
        <Col style={{textAlign:"center"}} >
        <h4>Coupon</h4>
        
        </Col>
        <Col style={{textAlign:"center"}}>
        <h4>Phonecard</h4>
        </Col>
      </Row>
      </div>
      </>
  }

  return (
    <>
      <div style={{ background: "#85c4ca", color: "white" }}>
        <Row style={{ height: "50px" }}></Row>
        <Row>
          <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
            {" "}
            <div
              style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
            >
              Puzzul
            </div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Community Governed, Socially Oriented, Data Management
            </div>
            <div style={{ color: "white", marginTop: "30px" }}>
              {template.description}
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <img height={360} src={Puzzul} alt="" />
          </Col>
        </Row>
        <Form style={{ padding: "30px" }}>
          {template.questions.map((e, index) => {
            if (e.type === "textBox") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>

                  <FormControl
                    onChange={(_e) => {
                      let text = _e.target.value;
                      let _info = { ...info };
                      _info[index] = text;
                      setInfo(_info);
                    }}
                    value={info[index]}
                    as="textarea"
                    aria-label="With textarea"
                    placeholder={e.placeholder}
                  />
                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            } else if (e.type === "noQuestion") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.text}</Form.Label>
                </Form.Group>
              );
            } else if (e.type === "multiChoice") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>

                  <Form.Control
                    as="select"
                    onClick={(_e) => {
                      let phrase = _e.target.value;

                      for (
                        let index = 0, length = e.options.length;
                        index < length;
                        index++
                      ) {
                        if (phrase === e.options[index]) {
                          let _info = { ...info };
                          _info[index] = index;
                          setInfo(_info);
                        }
                      }
                    }}
                  >
                    {e.options.map((e, index2) => {
                      return (
                        <option key={"Option" + index + "#" + index2}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            } else if (e.type === "text") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>
                  <Form.Control
                    onChange={(_e) => {
                      let text = _e.target.value;
                      let _info = { ...info };
                      _info[index] = text;
                      setInfo(_info);
                    }}
                    value={info[index]}
                    type="text"
                    placeholder={e.placeholder}
                  />
                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            }
          })}
          <Button
            variant="primary"
            onClick={async () => {
              let data = await submitForm({
                variables: {
                  uuid: uuid,
                  json: JSON.stringify(info),
                },
              });

              if (data) {
                setReward(true);
              }
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default SubmitForm;
