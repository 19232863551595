
import React, {useState} from "react";
import { useMutation } from "@apollo/client";
import { Row, Col, Button, Container, Navbar, Nav, Modal, Form} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import gqlQuery from "../GraphQL";
import Puzzul from '../image/Puzzul.svg';
import PersonQuote from '../image/PersonQuote.svg';

import PersonHold1 from '../image/PersonHold1.svg';
import PersonHold2 from '../image/PersonHold2.svg';
import PersonHold3 from '../image/PersonHold3.svg';

import Puzzle1 from '../image/puzzle1.svg';
import Puzzle2 from '../image/puzzle2.svg';
import Puzzle3 from '../image/puzzle3.svg';
import Puzzle4 from '../image/puzzle4.svg';
import Puzzle5 from '../image/puzzle5.svg';
import Puzzle6 from '../image/puzzle6.svg';


import HoldingUpPuzzlePiece from '../image/HoldingUpPuzzlePiece.svg';
import PuzzlePiecefrom from '../image/puzzlePiece.svg';

import WalletJS from '../WalletJS';




const HomePage = ({navigation}) => {

    let history = useHistory();
    const [show, setShow] = useState(false);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [createJWK] = useMutation(gqlQuery.ethereumLogin);
    const [_login] = useMutation(gqlQuery.login);


    return <>
    <>
      <Modal show={show} onHide={handleClose}       size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Form.Group style={{margin:"30px"}}>
                  <Form.Label>Login</Form.Label>
                  <Form.Control
                    onChange={(_e) => {
                        setLogin(_e.target.value)
                    }}
                    value={login}
                    type="text"
                    placeholder={"Email Address"}
                  />
                  <Form.Label style={{marginTop:"10px"}}>Password</Form.Label>
                                    <Form.Control
                    onChange={(_e) => {
                        setPassword(_e.target.value)
                    }}
                    value={password}
                    type="password"
                    placeholder={"Password"}
                  />
                </Form.Group>


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={async ()=>{
              
              let data = await _login({
                variables: {
                    email:login,
                    password:password
                  }
              })

              let _token = data.data.login
              if (_token){
                localStorage.setItem('token',_token);
                navigation.goDashBoard();
                history.push("/dashboard")
              }
          }}>
            Login
          </Button>

          <Button variant="primary" onClick={async ()=>{
              let [, token] = await WalletJS.login();

              let data = await createJWK({
                variables: {
                  jwt:token
                }
            })

            let _token = data.data.ethereumLogin
            
            localStorage.setItem('token',_token);
            navigation.goDashBoard();
            history.push("/dashboard")
          }}>
            Use Etheriuem
          </Button>
        </Modal.Footer>
      </Modal>
    </>    
    
    
    <div style={{ background: "#85c4ca" }}><Container >




              <Navbar expand="lg">
        <Navbar.Brand
          style={{ color: "white", fontSize: "30px", fontWeight: "bold" }}
        >
          {" "}
          <img src={Puzzul} height={32} style={{ marginRight: "20px" }} alt="Puzzul Logo"/>
          Puzzul
        </Navbar.Brand>
        <Nav className="mr-auto"></Nav>
        <Nav style={{ color: "white", fontSize: "30px", fontWeight: "bold" }} onClick={handleShow}>
            Login
          </Nav>
      </Navbar>

        <Row style={{ height: "50px" }}></Row>
        <Row>
            <Col style={{marginLeft:"20px", marginRight:"20px"}}> <div style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}>Puzzul</div>

                <div style={{ color: "white", fontSize: "30px" }}>
                    Community Governed, Socially Oriented, Data Management
        </div>
            </Col>
            <Col>
                <img height={360} src={Puzzul} alt="Puzzul Logo"/>
            </Col>
        </Row>
        <Row style={{ height: "50px" }}></Row>
    </Container>
        <div style={{ background: "#088ea7", padding: "60px" }}>
            <Row >
                <h3 style={{ textAlign: "center", color: "#FFFFFF" }}>“I never feel unsafe except for when the majority is on my side.”</h3>
            </Row>
            <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                    <div >
                        <h5 style={{ textAlign: "right", fontStyle: "italic", color: "#eb5858" }}>― Criss Jami
                </h5>

                    </div>
                </Col>
                <Col>
                    <img height={100} src={PersonQuote} alt=""/>
                </Col>
            </Row>
        </div>
        <Container >
            <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={PersonHold1} height={100} style={{ marginTop: "100px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>DATA IS THE NEW OIL</h3>

            <div style={{marginLeft:"40px", marginRight:"40px"}}>
            Companies are realizing that the data they have on their clients is becoming their most valuable resource.
                </div>

</Col>
                <Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={PersonHold2} height={170} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>BUT IT CAN BE BIASED</h3>
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                The majority culture creates the majority of data. This means it’s not representative of minority communities.
</div>
</Col>
                <Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={PersonHold3} height={100} style={{ marginTop: "100px" }} alt=""/>
                    </div>
                    <h3 style={{ color: "#088ea7" }}>THIS HURTS PEOPLE</h3>
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                Minorities have a history of not wanting to share data and being exploited when they do.
                </div>

</Col>

            </Row>
        </Container>
        <div style={{ background: "#088ea7", paddingLeft: "60px", paddingRight: "60ox" }}>

            <Row>

                <Col style={{ marginRight:"30px"}}>

                    <h1 style={{ marginTop: "40px", color: "#FFFFFF" }}>Puzzul gives vulnerable communities control of their data.</h1> </Col>
                <Col><img height={250} src={PuzzlePiecefrom} alt=""/></Col>

            </Row>
            <Row>
                <Col><img height={300} src={HoldingUpPuzzlePiece} alt=""/></Col>
                <Col style={{  marginRight:"30px" }}><h3 style={{ fontStyle: "italic", marginTop: "80px", color: "#FFFFFF",  }}>But let’s them work with companies so everyone can benefit.</h3></Col>
            </Row>
        </div>

        <Container style={{ color: "#FFFFFF" }}>
            <Row style={{ marginTop: "40px" }}>
                <Col></Col>
                <Col><h1>Values of Puzzul</h1></Col>
                <Col></Col>

            </Row>
            <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
                <Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle1} height={100} style={{ marginTop: "30px" }} alt=""/>
                    </div>
                    <h3 style={{ color: "#088ea7" }}>USER CONTROL</h3>

                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    The user defines where their data can be used.
                    </div>

</Col>
<Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle2} height={80} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>USER REIMBURSEMENT</h3>
                    
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    User’s and communities can be reimbursed for their data.
                    </div>
</Col>
<Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle3} height={100} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>COMMMUNITY CONTROL</h3>
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    Communities leaders can manage thousands of users.
                    </div>

</Col>

            
<Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle4} height={100} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>PRIVACY</h3>
                   
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    Only the data the user wishes to expose is displayed through the ZKP.
                    </div>

</Col>
<Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle5} height={100} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>GOVERNANCE</h3>
                    
                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    Data is secured using Blockchain technology allowing users to see where their data is used.
                    </div>

</Col>
<Col sm={4}>
                    <div style={{ textAlign: "center" }}>
                        <img src={Puzzle6} height={100} style={{ marginTop: "30px" }} alt="" />
                    </div>
                    <h3 style={{ color: "#088ea7" }}>PIPELINE</h3>

                    <div style={{marginLeft:"40px", marginRight:"40px"}}>
                    By using iModX Blockchain a data pipeline is created allowing turnkey integration.
        </div>


</Col>

            </Row>
        </Container>

        <div style={{ background: "#088ea7", paddingLeft: "60px", paddingRight: "60px", paddingTop:"40px", paddingBottom:"40px" }}>

           <Row>
               <Col style={{textAlign:"center"}}><h1>Let us solve your problem.</h1></Col>


           </Row>
           <Row>
               <Col>We are always ready to head about your problems or for people to reach out for a virtual cup of coffee.</Col>

           </Row>
           <Row style={{marginTop:"20px", marginBottom:"20px"}}>
               <Col></Col>
               <Col><Button style={{backgroundColor:"#eb5858"}}>Get in Touch</Button></Col>
               <Col></Col>
               </Row>
        </div>


    </div>
</>
}



export default HomePage;