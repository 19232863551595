import Puzzul from "../image/Puzzul.svg";
import { Row, Col, Form, FormControl} from "react-bootstrap";



function ViewForm({ navigation, template, info }) {


  return (
    <>
      <div style={{ background: "#85c4ca", color: "white" }}>
        <Row style={{ height: "50px" }}></Row>
        <Row>
          <Col style={{ marginLeft: "20px", marginRight: "20px" }}>
            {" "}
            <div
              style={{ color: "white", fontSize: "80px", fontWeight: "bold" }}
            >
              Puzzul
            </div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Community Governed, Socially Oriented, Data Management
            </div>
            <div style={{ color: "white", marginTop: "30px" }}>
              {template.description}
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <img height={360} src={Puzzul} alt="" />
          </Col>
        </Row>
        <Form style={{ padding: "30px" }}>
          {template.questions.map((e, index) => {
            if (e.type === "textBox") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>

                  <FormControl
readOnly={true}
                    value={info[index]}
                    as="textarea"
                    aria-label="With textarea"
                    placeholder={e.placeholder}
                  />
                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            } else if (e.type === "noQuestion") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.text}</Form.Label>
                </Form.Group>
              );
            } else if (e.type === "multiChoice") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>

                  <Form.Label>{info[index]}</Form.Label>

                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            } else if (e.type === "text") {
              return (
                <Form.Group key={"Questions" + index} controlId={"var" + index}>
                  <Form.Label>{e.label}</Form.Label>
                  <Form.Control
                    readOnly={true}
                    value={info[index]}
                    type="text"
                    placeholder={e.placeholder}
                  />
                  <Form.Text>{e.text}</Form.Text>
                </Form.Group>
              );
            }
          })}
        </Form>
      </div>
    </>
  );
}

export default ViewForm;
