import { Wallet, providers, utils } from "ethers";
import { soliditySha3  } from "web3-utils";
//hexToNumber
//const { soliditySha3 } = require("web3-utils");
//var web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:8545'))

const base64url = require("base64url");
//const { keccakFromString } = require('ethereumjs-util');



let javascriptWallet;

const createWallet = async () => {

  if (window.ethereum) {
    await window.ethereum.enable();
    const provider = new providers.Web3Provider(window.ethereum);
    return provider.getSigner();
  }

  if (!javascriptWallet) {
    javascriptWallet = Wallet.createRandom();
  }
  return javascriptWallet;
};

function rnd256() {
  return "0x" + Buffer.from(utils.randomBytes(32)).toString("hex");
}

const remoteSig = async (mode, recipient, target, nounce) => {

  if (!nounce){
     nounce = rnd256();  
  }
  const soliditySha3Expected = soliditySha3(mode, recipient, target, nounce);

  var wallet = await createWallet();
  var sig = await wallet.signMessage(utils.arrayify(soliditySha3Expected));

  const split = utils.splitSignature(sig);

  return {mode: mode, reciever:recipient, target:target, nounce:nounce, r:split.r, s:split.s,v:split.v}

};








const login = async () => {
  let wallet = await createWallet();

  var ts = Math.round((new Date()).getTime() / 1000)+3600*24;
   
  let key = {
      site:"puzzul.org",
      "t":"login",
      "ts":ts,
  }

  let jsonString = base64url(JSON.stringify(key));
  

  let sig = await wallet.signMessage(jsonString);
  let sigBuffer = Buffer.from(sig.substring(2), "hex")
  let address = utils.verifyMessage(jsonString,sig)
  sig =  base64url(sigBuffer);


  let header = base64url(JSON.stringify(  {
    "alg": "Platz",
    "typ": "JWT"
  }));


  return [address,header+"."+jsonString+"."+sig];
};



const verifyKey = (key)=>{

  if (typeof key !=="string"){
    return [null, null];
  }


  try {
      let [jsonString,sig] = key.split(".");
      sig = "0x"+base64url.toBuffer(sig).toString('hex');
      
      let address = utils.verifyMessage(jsonString,sig)
      
      try {
          jsonString = base64url.decode(jsonString);
          jsonString = JSON.parse(jsonString)
      } catch (error) {
          return [null, null];
      }

      var ts = Math.round((new Date()).getTime() / 1000);
      if (jsonString.ts >= ts){
          return [address, jsonString];

      }
      
      return [null,null]

      
      
  } catch (error) {
      console.log(error);
      return [null, null]
  }
 
}

const nounce = rnd256;  



const collection = { createWallet, login, remoteSig, nounce, verifyKey  };
export default collection;
