
import { useHistory } from "react-router-dom";

import { Table } from "react-bootstrap";

import ei from '../image/ei.svg';
import ge from '../image/ge.svg';
import re from '../image/re.png';
import se from '../image/se.svg';
import ne from '../image/ne.png';
import rd from '../image/rd.svg';

const TokenList = ({navigation, portfolio}) => 
{

  let history = useHistory();


  return <Table striped bordered hover style={{color:"white", backgroundColor:"rgb(8, 142, 167)"}}>
  <thead>
    <tr>
      <th>#</th>
      <th>Project Name</th>
      <th>Inital Investment</th>
      <th>Last Evaluation</th>
      <th>Ownership</th>

      <th><img src={ei} height={42} alt="Enviromental Impact"/></th>
      <th><img src={ge} height={42} alt="Gender Equity"/></th>
      <th><img src={re} height={42} alt="Racial Equity"/></th>
      <th><img src={se} height={42} alt="Sexual Expression Equity"/></th>
      <th><img src={ne} height={42} alt="Non English"/></th>
      <th><img src={rd} width={42} height={42} alt="Rural Divide"  /></th>
    </tr>
  </thead>
  <tbody>
      {portfolio.map((e, index)=>{
           return <tr key={"TokenReference:"+index} id ={e.address?e.address:e._id} onClick={(e)=>{
            //navigation.goAsset(e._id);
            history.push('asset/'+e.currentTarget.id)
            //console.log(e.currentTarget.id);

           }}>
           <td>{index+1}</td>
           <td>{e.name}</td>
           <td>{e.purchase.toLocaleString('en')  }</td>
           <td>{e.lastEval.toLocaleString('en')  }</td>
           <td>{e.ownership*100+"%"}</td>
           <td>{e.esg?(e.esg.ei*100).toFixed(1)+"%":"0%"}</td>
           <td>{e.esg?(e.esg.ge*100).toFixed(1)+"%":"0%"}</td>
           <td>{e.esg?(e.esg.re*100).toFixed(1)+"%":"0%"}</td>
           <td>{e.esg?(e.esg.se*100).toFixed(1)+"%":"0%"}</td>
           <td>{e.esg?(e.esg.ne*100).toFixed(1)+"%":"0%"}</td>
           <td>{e.esg?(e.esg.rd*100).toFixed(1)+"%":"0%"}</td>
         </tr>   
      })}
  </tbody>
</Table>
};

export default TokenList;
