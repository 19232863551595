import "./App.css";

import Portfolio from "./Components/Portfolio";
import HomePage from "./Components/Homepage";
import Asset from "./Components/Asset";
import ViewTemplate from './Components/ViewTemplate';
import TemplateManager from './Components/TemplateManager';
import SubmitForm from './Components/SubmitForm';

import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { HashRouter as Router, Route} from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import SecureForm from './Components/SecureForm';
// Create an http link:
const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {

  const navigation = {
    goDashBoard:() =>{
      //history.push("/dashboard");
    },
    goProject: (uid) => {
      //setProjectSelected(uid);
    },
    goAsset:(uid)=>{

    }
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <Route
          exact
          path="/"
          render={(props) => <HomePage navigation={navigation} />}
        />
        <Route
          exact
          path="/dashboard"
          render={(props) => <Portfolio navigation={navigation} />}
        />
        <Route
          exact
          path="/asset/:Lookup"
          render={(props) => <Asset {...props} navigation={navigation} />}
        />
        <Route
          exact
          path="/editTemplate/:Lookup"
          render={(props) => <ViewTemplate {...props} navigation={navigation} />}
        />

<Route
          exact
          path="/templates"
          render={(props) => <TemplateManager {...props} navigation={navigation} />}
        />

<Route
          exact
          path="/f/:Lookup"
          render={(props) => <SubmitForm {...props} navigation={navigation} />}
        />

<Route
          exact
          path="/s/:Lookup"
          render={(props) => <SecureForm {...props} navigation={navigation} />}
        />




      </Router>
    </ApolloProvider>
  );
}

export default App;

