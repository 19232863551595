
import { useHistory } from "react-router-dom";
import { Row,Col,Table } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import gqlQuery from "../GraphQL";

import { useState } from "react";
import ViewFrom from './ViewForm';

const AnsweredForms = ({navigation, address}) => 
{


    const {data } = useQuery(gqlQuery.getAnsweredForms, {
        variables: {id:address},
      });


    let forms = [];
    if (data){
        if (data.getAnsweredForms){
            forms = data.getAnsweredForms;
        }
    }

    return forms.map((e)=>{
        return <>
        <div style={{marginTop:"20px"}}></div>
         <SubAnsweredForms key={e._id} form={e}/>
        </>
    })
}

const SubAnsweredForms = ({navigation, form}) => 
{

const [answer, selectedAnswer] =useState({})
  let history = useHistory();



  return <Row>
      <Col>

      <h3>
        {form.name}
      </h3>



      <Table striped bordered hover style={{color:"white", backgroundColor:"rgb(8, 142, 167)"}}>
  <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>View</th>
    </tr>
  </thead>
  <tbody>
      {form.docs.map((e, index)=>{
           return <tr key={"TokenReference:"+index} onClick={()=>{

            selectedAnswer(e);
           }}>
           <td>{index+1}</td>
           <td>{e.date}</td>
           <td>{e.ip}</td>

         </tr>   
      })}
  </tbody>
</Table>
      </Col>
      <Col>
      <ViewFrom template={form} info={answer} />
      </Col>
      </Row>
      
      
      
};

export default AnsweredForms;
