import logo from "../image/Puzzul.svg";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import gqlQuery from "../GraphQL";
import { useQuery } from "@apollo/client";
function PortfolioPicker({ navigation}) {


    const {data } = useQuery(gqlQuery.getPortfolios, {
        variables: {},
    });


    let portfolio = [];

    if(data){
      if (data.getPortfolios){
        portfolio = data.getPortfolios;
      }
    }


  return (
    <Navbar expand="lg">
      <Navbar.Brand
        style={{ color: "white", fontSize: "30px", fontWeight: "bold" }}
      >
        {" "}
        <img src={logo} height={32} style={{ marginRight: "20px" }} alt=""/>
        Puzzul Dashboard
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav className="">
          <NavDropdown
            title="Portfolio"
            id="basic-nav-dropdown"
            style={{
              color: "white",
              fontSize: "30px",
              fontWeight: "bold",
              a: { color: "white" },
            }}
          >
            {portfolio.map((e) => {
              return <NavDropdown.Item>{e.name}</NavDropdown.Item>;
            })}
            <NavDropdown.Divider />
            <NavDropdown.Item>All</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default PortfolioPicker;
