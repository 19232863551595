import React from 'react'
import {  Doughnut } from 'react-chartjs-2'
import {getAssets} from '../DataGather';



import gqlQuery from "../GraphQL";
import { useQuery } from "@apollo/client";


const returnColors = (count)=>{


let colors2 = [
    "#088fa7",
    "#eb5858",
]

let colors3 = [
    "#088fa7",
    "#eeeeee",
    "#eb5858",
]

let colors5 = [
    "#088fa7",
    "#93beca",
    "#eeeeee",
    "#f5a6a0",
    "#eb5858",
]

let colors7 =[
    "#088fa7",
    "#72aebe",
    "#b2ced6",
    "#eeeeee",
    "#f5beb9",
    "#f48e87",
    "#eb5858"
]
let colors9 =[
    "#088fa7",
    "#60a6b8",
    "#93beca",
    "#c1d6dc",
    "#eeeeee",
    "#f4cac6",
    "#f5a6a0",
    "#f2817b",
    "#eb5858",
]
let colors11 =[
"#088fa7",
"#54a2b5",
"#7fb4c3",
"#a5c7d1",
"#cadadf",
"#eeeeee",
"#f3d1ce",
"#f6b5af",
"#f59891",
"#f17974",
"#eb5858"
]
let colors13 =[
"#088fa7",
"#4c9eb3",
"#72aebe",
"#93beca",
"#b2ced6",
"#d0dee2",
"#eeeeee",
"#f3d6d3",
"#f5beb9",
"#f5a6a0",
"#f48e87",
"#f0746f",
"#eb5858",
];


if (count<3){
    return colors2;
} else if (count===3){
    return colors3;
} else if (count<6){
    return colors5.slice(0,count);
} else if (count<8){
    return colors7.slice(0,count);
} else if (count<10){
    return colors9.slice(0,count);
} else if (count<12){
    return colors11.slice(0,count);
} else if (count<14){
    return colors13.slice(0,count);
} else {

}



}



const dateFromAssets= (assets)=>{


    let colors = returnColors(assets.length);
    console.log(assets.map((e)=>{
        return e.lastEval;
    }))

    return {
        labels: assets.map((e)=>{
            return e.name;
        }),
        datasets: [
          {
            label: 'Total Assets',
            data: assets.map((e)=>{
                return e.lastEval;
            }),
            backgroundColor: colors.slice(0, assets.length ),
            borderColor:colors.slice(0, assets.length ),
            borderWidth: 1,
          },
        ],
      }

}

const options =         {
    legend:{
        display: false
        }
}

const DoughnutChart = ({assets}) => {


    console.log(assets);
    if (!assets){
        return <></>
    }
      if (assets.length===0){
          return <></>
      }
      assets = assets.slice(0,12);
    
      



  return <>
    <Doughnut data={dateFromAssets(assets)} options={options} />
  </>
}

export default DoughnutChart