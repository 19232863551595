import gqlQuery from "../GraphQL";
import PortfolioPicker from '../Components/PortfolioPicker';
import {Row, Col, Container} from "react-bootstrap";
import { useQuery } from "@apollo/client";
import TemplatesAttachedToAsset from './TemplatesAttachedToAsset'
import AnsweredForms from "./AnsweredForms";

function  Asset({navigation,match}) {

  const {data } = useQuery(gqlQuery.getAsset, {
    variables: {id:match.params.Lookup},
  });


  let info = {};

  if (data){
      if (data.getAsset){
          if(data.getAsset.asset){

        
            info=data.getAsset.asset;


          }
      }
  }



  console.log(info);

  
  return (
    <div
      className="App"
      style={{
        backgroundColor: "rgb(133, 196, 202)",
        color: "white",
      }}
    >
       <Container>
      <PortfolioPicker navigation={navigation}/>

 <Row>
     <Col style={{textAlign:"left"}}>
     <h4>Name: {info.name}</h4>
    <h4>Initial Evaluation: ${info.purchase?info.purchase.toLocaleString('en'):0}</h4>
    <h4>Current Evaluation: ${info.lastEval?info.lastEval.toLocaleString('en'):0}</h4>
    <h4>Ownership Percentage: {info.ownership*100+"%"}</h4>
     </Col>
     <Col>
     <Row>
         <Col>
         <h4>Enviromental Impact: {info.esg?info.esg.ei*100+"%":"0%"}</h4>
         </Col>
         <Col>
         <h4>Gender Equality: {info.esg?info.esg.ge*100+"%":"0%"}</h4>
         </Col>
     </Row>
     <Row>
     <Col>
     <h4>Racial Equality: {info.esg?info.esg.re*100+"%":"0%"}</h4>
     </Col>
         <Col>
         <h4> Sexual Express Equality: {info.esg?info.esg.se*100+"%":"0%"}
         
         </h4>
         </Col>
     </Row>
     <Row>
     
     </Row>

     </Col>
 </Row>
 </Container>
 <div style={{
     background:"rgb(8, 142, 167)",
 }}>
    <Container>
<TemplatesAttachedToAsset address={match.params.Lookup}/>
</Container>
</div>

    <Container>
<AnsweredForms address={match.params.Lookup}/>
</Container>

    </div>
  );
}


export default Asset;