import gql from "graphql-tag";

const getAPortfolio = gql`
  query getAPortfolio($id: String!) {
    getAPortfolio(id: $id) {
      name
      start
      address
      purchase
      lastEval
      ownership
      esg {
        ei
        ge
        re
        se
        ne
        rd
      }
    }
  }
`;

const getTemplates = gql`
  query {
    getTemplates {
      _id
      name
      description
      questions {
        varName
        label
        text
        placeholder
        type
        options
      }
    }
  }
`;

const getTemplate = gql`
  query getTemplate($id: String!) {
    getTemplate(id: $id) {
      _id
      name
      description
      questions {
        varName
        label
        text
        placeholder
        type
        options
      }
    }
  }
`;

const getAsset = gql`
  query getAsset($id: String!) {
    getAsset(id: $id) {
      asset {
        name
        start
        address
        purchase
        lastEval
        ownership
        esg {
          ei
          ge
          re
          se
          ne
          rd
        }
      }
      docs {
        _id
        name
      }
      secureDocs {
        _id
        name
      }
    }
  }
`;

const getPortfolios = gql`
  query {
    getPortfolios {
      _id
      name
    }
  }
`;

const login = gql`
mutation login($email: String!, $password:String!) {
  login(email: $email, password:$password)
}
`;

const ethereumLogin = gql`
  mutation ethereumLogin($jwt: String!) {
    ethereumLogin(jwt: $jwt)
  }
`;

const updateTemplate = gql`
  mutation updateTemplate($json: String!) {
    updateTemplate(json: $json)
  }
`;

const addTemplateToToken = gql`
  mutation addTemplateToToken($asset: String!, $template: String!) {
    addTemplateToToken(asset: $asset, template: $template)
  }
`;

const removeTemplateFromToken = gql`
  mutation removeTemplateFromToken($asset: String!, $template: String!) {
    removeTemplateFromToken(asset: $asset, template: $template)
  }
`;

const getLinkForAsset = gql`
  query getLinkForAsset($asset: String!, $template: String!) {
    getLinkForAsset(asset: $asset, template: $template)
  }
`;

const getAForm = gql`
  query getAForm($id: String!) {
    getAForm(id: $id) {
      _id
      name
      description
      questions {
        varName
        label
        text
        placeholder
        type
        options
      }
    }
  }
`;

const answerASecureForm =  gql`
  mutation answerASecureForm($uuid: String!, $json:String!) {
    answerASecureForm(uuid: $uuid, json: $json)
  }
`;


const answerAForm =  gql`
  mutation answerAForm($uuid: String!, $json:String!) {
    answerAForm(uuid: $uuid, json: $json)
  }
`;


const getAnsweredForms = gql`
query getAnsweredForms($id:String!){
  getAnsweredForms(id:$id){
    _id
    name
    description
    questions{
      label
  varName
  text
  placeholder
  type
  options
    }
    docs
  }
  }
`;

const combine = {
  addTemplateToToken,
  getAnsweredForms,
  removeTemplateFromToken,
  answerAForm,
  answerASecureForm,
  getAForm,
  getLinkForAsset,
  getTemplates,
  getTemplate,
  login,
  ethereumLogin,
  getAsset,
  getPortfolios,
  getAPortfolio,
  updateTemplate,
};

export default combine;
