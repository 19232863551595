import base64url from "base64url";

class WalletObjectsJSON {
    encKey: JsonWebKey;
    signKey: JsonWebKey;
    date: string;
    meta?: any;
  
    constructor(
      encKey: JsonWebKey,
      signKey: JsonWebKey, 
      date: string) {
      this.encKey = encKey;
      this.signKey = signKey;
      this.date = date;
    }
  
  }

interface WalletObjects {
  encKey: {
    publicKey: CryptoKey,
    privateKey: CryptoKey,
  },
  signKey: {
    publicKey: CryptoKey,
    privateKey: CryptoKey
  }
}

interface PrivateKeyObject {
  encKey: {
    publicKey: JsonWebKey,
    privateKey: JsonWebKey
  },
  signKey: {
    publicKey: JsonWebKey,
    privateKey: JsonWebKey
  }
}




interface Payload {
  iv: string;
  payload: string;
}

const cleanPayload = (payload: Payload) => {
  return { iv: payload.iv, payload: payload.payload } as Payload;

}

class MultipartyEncyptedDoc {
  hash: string;
  signer: Payload | undefined;
  sig: string;
  payload: Payload;
  publicKey: JsonWebKey | undefined;
  clean() {
    return JSON.parse(JSON.stringify(new MultipartyEncyptedDoc(this.hash, this.signer, this.sig, this.payload, this.publicKey)));
  }
  constructor(hash: string, signer: Payload | undefined, sig: string, payload: Payload, publicKey: JsonWebKey | undefined) {
    this.hash = hash;
    this.signer = signer ? cleanPayload(signer) : undefined;
    this.sig = sig;
    this.payload = cleanPayload(payload);
    this.publicKey = publicKey ? publicKey : undefined;
  }
}

export type MultipartyEncyptedContainer = {
  doc: MultipartyEncyptedDoc
  keys: string[];

}


function decryptWithPassword(pwHash: ArrayBuffer, iv: Uint8Array, arrayBuffer: ArrayBuffer) {
  const alg = { name: "AES-GCM", iv: iv };
  return window.crypto.subtle
    .importKey("raw", pwHash, alg, false, [
      "encrypt",
      "wrapKey",
      "unwrapKey",
      "decrypt",
    ])
    .then((encryptKey) => {
      return window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",

          //Don't re-use initialization vectors!
          //Always generate a new iv every time your encrypt!
          //Recommended to use 12 bytes length
          iv: iv,

          //Additional authentication data (optional)
          //additionalData: ArrayBuffer,

          //Tag length (optional)
          tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
        },
        encryptKey, //from generateKey or importKey above
        arrayBuffer //ArrayBuffer of data you want to encrypt
      );
    });
}

var saveBlock = (exportData: object, fileName: string) => {
  const element = document.createElement("a");
  const file = URL.createObjectURL(
    new Blob([JSON.stringify(exportData)], { type: "text/json" })
  );
  element.href = file;
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

async function createKeys():Promise<WalletObjects> {
  const exportKey = true;
  let docs =  await Promise.all([
    window.crypto.subtle.generateKey(
      {
        name: 'ECDSA',
        namedCurve: 'P-256', //can be "P-256", "P-384", or "P-521"
      },
      exportKey, //whether the key is extractable (i.e. can be used in exportKey)
      ['sign', 'verify'] //can be any combination of "sign" and "verify"
    ),
    window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048, //can be 1024, 2048, or 4096
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: { name: 'SHA-256' }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      exportKey, //whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'] //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
    )
  ]);
  return {signKey:docs[0] as CryptoKeyPair, encKey:docs[1] as CryptoKeyPair }
}

var importPrivateBlock = async (block: PrivateKeyObject) => {
  let keyConvert = [
    window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      block.encKey.publicKey,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ["encrypt"] //"verify" for public key import, "sign" for private key imports
    ),

    window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      block.encKey.privateKey,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ["decrypt"] //"verify" for public key import, "sign" for private key imports
    ),

    window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      block.signKey.publicKey,
      {
        name: "ECDSA",
        namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ["verify"] //"verify" for public key import, "sign" for private key imports
    ),
    window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      block.signKey.privateKey,
      {
        name: "ECDSA",
        namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ["sign"] //"verify" for public key import, "sign" for private key imports
    ),
  ]


  let arrayOfPromies = await Promise.all(keyConvert);//.then((arrayOfPromies) => {
  /*return WalletObjects{
    encKey : { publicKey: arrayOfPromies[0], privateKey: arrayOfPromies[1] },
      signKey :  { publicKey: arrayOfPromies[2], privateKey: arrayOfPromies[3] },
      uuid : block.uuid
  }
  */
  return {
    encKey: { publicKey: arrayOfPromies[0], privateKey: arrayOfPromies[1] },
    signKey: { publicKey: arrayOfPromies[2], privateKey: arrayOfPromies[3] },
  } as WalletObjects;
  //return exportData;
};

function encryptWithPassword(pwHash: ArrayBuffer, iv: Uint8Array, arrayBuffer: ArrayBuffer) {
  const alg = { name: "AES-GCM" };
  return window.crypto.subtle
    .importKey("raw", pwHash, alg, false, [
      "encrypt",
      "wrapKey",
      "unwrapKey",
      "decrypt",
    ])
    .then((encryptKey) => {
      return window.crypto.subtle.encrypt(
        {
          name: "AES-GCM",

          //Don't re-use initialization vectors!
          //Always generate a new iv every time your encrypt!
          //Recommended to use 12 bytes length
          iv,

          //Additional authentication data (optional)
          //additionalData: ArrayBuffer,

          //Tag length (optional)
          tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
        },
        encryptKey, //from generateKey or importKey above
        arrayBuffer //ArrayBuffer of data you want to encrypt
      );
    });
}

var hashStringBase64 = (string:string) => {
  return hashString(string).then((buffer) => {
    return arrayBufferToBase64(buffer);
  });
}


var hashBuffer  = async (buffer:any)=>{
    let aBuffer = await window.crypto.subtle.digest(
        {
          name: "SHA-256",
        },
        new Uint8Array(buffer) //The data you want to hash as an ArrayBuffer
      );
      return Buffer.from(aBuffer);
}

var hashBufferToBase64 = async (buffer:any)=>{
    let aBuffer = await window.crypto.subtle.digest(
        {
          name: "SHA-256",
        },
        new Uint8Array(buffer) //The data you want to hash as an ArrayBuffer
      );
      return base64url(Buffer.from(aBuffer));
}

var hashStringBase64URL = (string:string) => {

  return hashString(string).then((buffer) => {
    return arrayBufferToBase64(Buffer.from(buffer));
  });
}
var hashString = (string:string) => {
  let buffer = stringToArrayBuffer(string);
  return window.crypto.subtle.digest(
    {
      name: "SHA-256",
    },
    new Uint8Array(buffer) //The data you want to hash as an ArrayBuffer
  );
};

var arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
  let result = base64url(Buffer.from(arrayBuffer)) as string;
  return result;
};

var base64ToArrayBuffer = (base64: string) => {
  return base64url.toBuffer(base64);
};

function arrayBufferToString(buf:any) {
  var enc = new TextDecoder();
  return enc.decode(buf);
}

function stringToArrayBuffer(str: string) {
  var encX = new TextEncoder(); // always utf-8
  return encX.encode(str);
}

let unlockKeys = async (data: string, password: string, iv: string) => {

  let buffer = await unlockProtectData(data, password, iv);
  let stringText = arrayBufferToString(buffer);
  stringText = checkIfQuotedString(stringText);
  let _json = JSON.parse(stringText);
  let wallet =  await importPrivateBlock(_json);
  return {
    encKey: wallet.encKey,
    signKey: wallet.signKey,
    hash: _json.hash
  }
}

let unlockProtectData = (data: string, password: string, iv: string) => {
  return hashString(password).then((password_hash) => {
    return decryptWithPassword(password_hash, base64ToArrayBuffer(iv), base64ToArrayBuffer(data))
  })
}

let passwordProtectData = (data: ArrayBuffer, password: string| ArrayBuffer) => {

    let iv = window.crypto.getRandomValues(new Uint8Array(12));

    if (typeof password  == "string"){
        return hashString(password).then((code) => {
            return encryptWithPassword(code, iv, data).then((encryptedData) => {
              return Promise.all([
                arrayBufferToBase64(iv),
                arrayBufferToBase64(encryptedData),
              ]).then((arrayBase64) => {
                return {
                  iv: arrayBase64[0],
                  payload: arrayBase64[1],
                };
              });
            });
          });
    } else {
        return encryptWithPassword(password, iv, data).then((encryptedData) => {
            return Promise.all([
              arrayBufferToBase64(iv),
              arrayBufferToBase64(encryptedData),
            ]).then((arrayBase64) => {
              return {
                iv: arrayBase64[0],
                payload: arrayBase64[1],
              };
            });
          });
    }
};

const exportPrivateBlock = (encKey: CryptoKeyPair, signKey: CryptoKeyPair, uuid: string, password: string) => {
  let promises = [
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      encKey.publicKey //can be a publicKey or privateKey, as long as extractable was true
    ),
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      encKey.privateKey //can be a publicKey or privateKey, as long as extractable was true
    ),

    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      signKey.publicKey //can be a publicKey or privateKey, as long as extractable was true
    ),

    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      signKey.privateKey //can be a publicKey or privateKey, as long as extractable was true
    )
  ];

  return Promise.all(promises).then((data) => {
    let exportData = {
      encKey: {
        publicKey: data[0],
        privateKey: data[1],
      },
      signKey: {
        publicKey: data[2],
        privateKey: data[3],
      },
      hash: uuid
    };

    return passwordProtectData(stringToArrayBuffer(JSON.stringify(exportData)), password);
  });
};


const exportEncUnsafe = (encKey:CryptoKeyPair)=>{

    let _pubKey : CryptoKey;
    let _priKey : CryptoKey;

    _pubKey = encKey.publicKey;
    _priKey = encKey.privateKey;
  

  let promises = [
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      _pubKey //can be a publicKey or privateKey, as long as extractable was true
    ),
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      _priKey //can be a publicKey or privateKey, as long as extractable was true
    ),
  ];

  return Promise.all(promises).then((data) => {
    return {
        pri:data[0],
        pub:data[1]
    };
  });

}

const exportPublicBlock = (encKey:CryptoKey|CryptoKeyPair, signKey:CryptoKey|CryptoKeyPair) => {

  let _encKey : CryptoKey;
  let _signKey : CryptoKey;

  if (encKey instanceof CryptoKey) {
    _encKey = encKey;
  } else {
    _encKey = encKey.publicKey;

  }

  if (signKey instanceof CryptoKey) {
    _signKey = signKey;
  } else {
    _signKey = signKey.publicKey;
  }


  let promises = [
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      _encKey //can be a publicKey or privateKey, as long as extractable was true
    ),
    window.crypto.subtle.exportKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      _signKey //can be a publicKey or privateKey, as long as extractable was true
    ),
  ];

  return Promise.all(promises).then((data) => {
    return new WalletObjectsJSON(data[0],data[1],new Date().toISOString());
  });
};

const checkIfQuotedString = (textString:string) => {

  if (textString[0] === "\"") {
    return textString.substring(1, textString.length - 1)
  }
  return textString;

}

async function signObject(signKey: CryptoKey, json: object): Promise<string> {
  let arrayBuffer = stringToArrayBuffer(JSON.stringify(json));
  let signature = await signBuffer(signKey, arrayBuffer);

  return  arrayBufferToBase64(signature);
}


const signBuffer = (signKey: CryptoKey, buffer: Uint8Array) => {
  return window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    signKey, //from generateKey or importKey above
    buffer //ArrayBuffer of data you want to sign
  );
};

var verifyObject = async (publicKey: any, object: object, sigbuffer: string) => {



  let _publicKey = publicKey;//await importKey(publicKey);
  let _buffer = stringToArrayBuffer(JSON.stringify(object));
  let _signBuffer = base64ToArrayBuffer(sigbuffer);
  return await verifyBuffer(_publicKey, _buffer, _signBuffer);

}

var verifyBuffer = (publicKey: any, buffer: ArrayBuffer, sigbuffer: Uint8Array) => {
  return window.crypto.subtle.verify(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    publicKey, //from generateKey or importKey above
    sigbuffer, //ArrayBuffer of the signature
    buffer //ArrayBuffer of the data
  );
};






var encryptBuffer = async (encKey: CryptoKey | JsonWebKey, buffer: ArrayBuffer) => {


  let keyToUse: CryptoKey;
  if (encKey instanceof CryptoKey) {
    keyToUse = encKey;
  } else {
    keyToUse = await window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      encKey,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ["encrypt"] //"verify" for public key import, "sign" for private key imports
    )
  }

  return window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
      //label: Uint8Array([...]) //optional
    },
    keyToUse, //from generateKey or importKey above
    buffer //ArrayBuffer of data you want to encrypt
  );
};

var createEncryptedDocumentForMultiplePeople = async (object: object, publicEncs: Array<CryptoKey | JsonWebKey>): Promise<MultipartyEncyptedContainer> => {

  let code = window.crypto.getRandomValues(new Uint8Array(32));
  var data = new TextEncoder().encode(JSON.stringify(object));
  let passwordProtectedData = await passwordProtectData(data, code);

  let passkeys: Array<string> = [];
  for (let i = 0, l = publicEncs.length; i < l; i++) {
    let encryptCode = await encryptBuffer(publicEncs[i], code);
    passkeys.push(await arrayBufferToBase64(encryptCode));
  }

  let toBuffer = stringToArrayBuffer(JSON.stringify(passwordProtectedData));
  let hash = await hashStringBase64URL(JSON.stringify(passwordProtectedData));


  let key = await window.crypto.subtle.generateKey(
    {
      name: 'ECDSA',
      namedCurve: 'P-256', //can be "P-256", "P-384", or "P-521"
    },
    true, //whether the key is extractable (i.e. can be used in exportKey)
    ['sign', 'verify'] //can be any combination of "sign" and "verify"
  )

  let publicKey = await window.crypto.subtle.exportKey(
    "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
    key.publicKey //can be a publicKey or privateKey, as long as extractable was true
  )
  let privateKey = await window.crypto.subtle.exportKey(
    "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
    key.privateKey //can be a publicKey or privateKey, as long as extractable was true
  )

  let signer = await passwordProtectData(new TextEncoder().encode(JSON.stringify(privateKey)), code)

  let signiture = await window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    key.privateKey, //from generateKey or importKey above
    toBuffer //ArrayBuffer of data you want to sign
  );

  return {
    doc: new MultipartyEncyptedDoc(hash, signer, arrayBufferToBase64(signiture), passwordProtectedData, publicKey),
    keys: passkeys,

  }
}

var appendEncryptedDocument = async (object: object, code: Uint8Array, key: CryptoKey | JsonWebKey): Promise<MultipartyEncyptedDoc> => {


  var data = new TextEncoder().encode(JSON.stringify(object));
  let passwordProtectedData = await passwordProtectData(data, code);

  let toBuffer = stringToArrayBuffer(JSON.stringify(passwordProtectedData));
  let hash = await hashBufferToBase64(toBuffer);


  let keyToUse: CryptoKey;
  if (key instanceof CryptoKey) {
    keyToUse = key;
  } else {
    keyToUse = await window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      key,
      {
        name: "ECDSA",
        namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ["sign"] //"verify" for public key import, "sign" for private key imports
    )
  }

  let signiture = await window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    keyToUse, //from generateKey or importKey above
    toBuffer //ArrayBuffer of data you want to sign
  );

  return new MultipartyEncyptedDoc(hash, undefined, arrayBufferToBase64(signiture), passwordProtectedData, undefined);

}


var unlockEncryptedDocumentForMultiplePeople = async (object: MultipartyEncyptedDoc, password: string, decryptKey: CryptoKey| JsonWebKey, headKey?: CryptoKey) => {

    let _decryptKey: CryptoKey;
    if (decryptKey instanceof CryptoKey) {
        _decryptKey = decryptKey;
    } else {
        _decryptKey = await window.crypto.subtle.importKey(
            "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
            decryptKey,
            {
              name: "RSA-OAEP",
              hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
            },
            false, //whether the key is extractable (i.e. can be used in exportKey)
            ["decrypt"] //"verify" for public key import, "sign" for private key imports
          );
    }


  object = new MultipartyEncyptedDoc(
    object.hash,
    object.signer,
    object.sig,
    object.payload,
    object.publicKey
  )
  let code: ArrayBuffer;
  try {
    code = await window.crypto.subtle.decrypt(
      {
        name: "RSA-OAEP",
      },
      _decryptKey,
      base64ToArrayBuffer(password)
    );
  } catch (error) {
    throw new Error("Password Not Valid");
  }




  if (object.publicKey) {

    let verifykey = await window.crypto.subtle.importKey(
      "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      object.publicKey,
      {
        name: "ECDSA",
        namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ["verify"] //"verify" for public key import, "sign" for private key imports
    )

    let verify = await window.crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      verifykey, //from generateKey or importKey above
      base64ToArrayBuffer(object.sig), //ArrayBuffer of the signature
      stringToArrayBuffer(JSON.stringify(object.payload)) //ArrayBuffer of the data
    );

    if (verify) {
      const alg = { name: "AES-GCM", iv: base64ToArrayBuffer(object.payload.iv) };
      let passwordKey = await window.crypto.subtle.importKey("raw", code, alg, false, [
        "encrypt",
        "wrapKey",
        "unwrapKey",
        "decrypt",
      ])


      let objectBuffer = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: base64ToArrayBuffer(object.payload.iv),
          tagLength: 128,
        },
        passwordKey,
        base64ToArrayBuffer(object.payload.payload)
      );

      let signer;
      if (object.signer){
        let keyBuffer = await window.crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: base64ToArrayBuffer(object.signer.iv),
            tagLength: 128,
          },
          passwordKey,
          base64ToArrayBuffer(object.signer.payload)
        );

        let key = JSON.parse(arrayBufferToString(keyBuffer));

        signer = await window.crypto.subtle.importKey(
          "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
          key,
          {
            name: "ECDSA",
            namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
          },
          true, //whether the key is extractable (i.e. can be used in exportKey)
          ["sign"] //"verify" for public key import, "sign" for private key imports
        )


      }




      let end = JSON.parse(arrayBufferToString(objectBuffer));
      return {
        code,
        object:end,
        headKey:verifykey,
        signer
      };
    } else {
      throw new Error("Not Signed");
    }

  } else if (headKey) {
    let verify = await window.crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      headKey, //from generateKey or importKey above
      base64ToArrayBuffer(object.sig), //ArrayBuffer of the signature
      stringToArrayBuffer(JSON.stringify(object.payload)) //ArrayBuffer of the data
    );

    if (verify) {
      const alg = { name: "AES-GCM", iv: base64ToArrayBuffer(object.payload.iv) };
      let passwordKey = await window.crypto.subtle.importKey("raw", code, alg, false, [
        "encrypt",
        "wrapKey",
        "unwrapKey",
        "decrypt",
      ])


      let objectBuffer = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: base64ToArrayBuffer(object.payload.iv),
          tagLength: 128,
        },
        passwordKey,
        base64ToArrayBuffer(object.payload.payload)
      );
      let end = JSON.parse(arrayBufferToString(objectBuffer));
      return {
        code:code,
        object:end,
        headKey,
        signer:undefined
      };
    } else {
      throw new Error("Not Signed");
    }
  } else {

    throw new Error("No Public Key");

  }
}

var changeKeyToString = (jwk : JsonWebKey)=>{
  return jwk.x + "~" + jwk.y

}
var changeStringToKey = (string:string)=>{
  let splitString = string.split("~");
  return {
    "kty": "EC",
    "crv": "P-256",
    "key_ops": [
      "verify"
    ],
    "x": splitString[0],
    "y": splitString[1]
  } as JsonWebKey
}



export {
  unlockKeys,
  saveBlock,
  signObject,
  exportPublicBlock,
  exportPrivateBlock,
  createKeys,
  verifyObject,

  changeKeyToString,
  changeStringToKey,

  exportEncUnsafe,


  arrayBufferToString,
  arrayBufferToBase64,
  base64ToArrayBuffer,
  hashStringBase64,
  createEncryptedDocumentForMultiplePeople,
  appendEncryptedDocument,
  unlockEncryptedDocumentForMultiplePeople,
  MultipartyEncyptedDoc,
}